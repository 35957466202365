import { VFC, useRef, useEffect } from "react";

import { useClearRefinements, useMenu } from "react-instantsearch-hooks-web";
import { Grid } from "theme-ui";

import * as analytics from "src/lib/analytics";
import { Selectable } from "src/ui/selectable";

type Props = {
  currentCategory: string;
  attribute: string;
};

export const CategoryMenu: VFC<Props> = ({ currentCategory, attribute }) => {
  const menuApi = useMenu({
    attribute,
    limit: 30,
    sortBy: ["name:asc"],
  });
  const { refine: clearRefinements } = useClearRefinements();
  const categories = useRef<Array<{ label: string; isRefined: boolean; value: string }>>([]);

  useEffect(() => {
    if (menuApi?.items?.length > categories.current?.length) {
      categories.current = menuApi?.items;
    }
  }, [menuApi?.items?.length]);

  return (
    <Grid gap={1}>
      <Selectable
        selected={false}
        sx={{
          textTransform: "capitalize",
          px: 2,
          py: 1,
          borderRadius: 1,
          fontSize: 1,
          fontWeight: "bold",
          cursor: "pointer",
          color: currentCategory === "All" ? "primary" : "base.5",
          bg: currentCategory === "All" ? "primaries.0" : undefined,
          border: "none",
          ":hover": {
            color: currentCategory === "All" ? undefined : "primary",
          },
        }}
        onSelect={() => {
          clearRefinements();
          analytics.track("Destination category clicked", { category: "All" });
        }}
      >
        All
      </Selectable>
      {categories?.current?.map((filter) => {
        return (
          <Selectable
            key={filter.label}
            selected={currentCategory === filter.label}
            sx={{
              textTransform: "capitalize",
              px: 2,
              py: 1,
              borderRadius: 1,
              fontSize: 1,
              fontWeight: "bold",
              cursor: "pointer",
              color: currentCategory === filter.label ? "primary" : "base.5",
              bg: currentCategory === filter.label ? "primaries.0" : undefined,
              border: "none",
              ":hover": {
                color: currentCategory === filter.label ? undefined : "primary",
              },
            }}
            onSelect={() => {
              menuApi?.refine(filter.value);
              analytics.track("Destination category clicked", { category: filter.label });
            }}
          >
            {filter.label}
          </Selectable>
        );
      })}
    </Grid>
  );
};
