import { forwardRef } from "react";

import { Grid, Image, Text } from "theme-ui";

import { DestinationDefinitionFragment as DestinationDefinition } from "src/graphql";
import { Row, Column } from "src/ui/box";
import { CheckList, CheckListItem } from "src/ui/checklist";
import { Heading } from "src/ui/heading";

type DetailsProps = {
  definition: DestinationDefinition | null;
  top: number;
  ref: HTMLDivElement | null;
};

export const DestinationDetails = forwardRef<HTMLDivElement, DetailsProps>(({ definition, top }, ref) => {
  return (
    <Column
      sx={{
        position: "sticky",
        top: `${top}px`,
        opacity: definition ? 1 : 0,
        width: definition ? "auto" : 0,
        height: definition ? "max-content" : 0,
        minWidth: definition ? "284px" : 0,
        maxWidth: "334px",
        borderLeft: "small",
        borderColor: "base.2",
        color: "base.7",
        borderRadius: 1,
        pl: 9,
        transition: "opacity 150ms ease-in-out",
        zIndex: 1,
        "@media screen and (max-width: 1280px)": {
          display: "none",
        },
      }}
    >
      <Column ref={ref} sx={{ maxHeight: `calc(100vh - ${top + 105}px)`, overflow: "auto", pr: 6 }}>
        <Row sx={{ alignItems: "center", mb: 6 }}>
          <Image src={`${definition?.icon}?h=48`} sx={{ mr: 2, minWidth: "24px", flex: 0 }} />
          <Heading sx={{ fontSize: 3, fontWeight: "bold" }} variant="h3">
            {definition?.name}
          </Heading>
        </Row>
        <Grid gap={6}>
          {definition?.shortPitch && (
            <Column>
              <Text sx={{ fontSize: 0, color: "base.5", fontWeight: "bold", mb: 2 }}>ABOUT</Text>
              <Text sx={{ fontWeight: "semi" }}>{definition?.shortPitch}</Text>
            </Column>
          )}
          {definition?.longPitch && (
            <Column>
              <Text sx={{ fontSize: 0, color: "base.5", fontWeight: "bold", mb: 2 }}>WHY?</Text>
              <Text sx={{ fontWeight: "semi" }}>{definition?.longPitch}</Text>
            </Column>
          )}
          {definition?.features && definition?.features?.length > 0 && (
            <Column>
              <Text sx={{ fontSize: 0, color: "base.5", fontWeight: "bold", mb: -2 }}>USE CASES</Text>
              <CheckList>
                {definition?.features.map((value) => (
                  <CheckListItem key={value}>
                    <Text sx={{ fontWeight: "semi", color: "base.7" }}>{value}</Text>
                  </CheckListItem>
                ))}
              </CheckList>
            </Column>
          )}
        </Grid>
      </Column>
    </Column>
  );
});

DestinationDetails.displayName = "Destination Details";
