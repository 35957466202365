import { VFC } from "react";

import { Highlight } from "react-instantsearch-hooks-web";
import { Grid, Image, Text } from "theme-ui";

import * as analytics from "src/lib/analytics";
import { Column, Row } from "src/ui/box";
import { DestinationIcon } from "src/ui/icons";
import { Link } from "src/ui/link";
import { Selectable } from "src/ui/selectable";

type DestinationShadowProps = {
  category: string;
  selected: boolean;
  hit: any;
  query: string;
  sendEvent: (eventType: string, hit: any, eventName?: string) => void;
};

export const DestinationShadow: VFC<Readonly<DestinationShadowProps>> = ({ category, selected, hit, query, sendEvent }) => {
  if (!hit) {
    return null;
  }

  const messageIntercom = (hit) => {
    if (typeof (window as any)?.Intercom !== "undefined") {
      (window as any)?.Intercom(
        "showNewMessage",
        `Hi, I would like to request a new destination. I want to sync data to ${hit.name} in order to help the _____ team accomplish _____.`,
      );
    }
  };

  const selectShadow = (hit) => {
    messageIntercom(hit);
    analytics.track("Destination Clicked", {
      destination_type: hit.name,
      destination_status: hit.status,
      destination_category: category,
    });
    sendEvent("click", hit, "Shadow Destination clicked");
  };

  return (
    <Selectable
      selected={selected}
      sx={{
        position: "relative",
        justifyContent: "start",
        px: 5,
        py: 3,
        border: "none",
        borderBottom: "small",
        borderRadius: 0,
        overflow: "hidden",
        ":hover": {
          color: "primary",
        },
      }}
      onSelect={() => selectShadow(hit)}
    >
      <Grid columns="repeat(2, 1fr)" gap={4}>
        <Row sx={{ alignItems: "center" }}>
          {hit?.icon && (
            <Image
              src={`${hit?.icon}?h=40`}
              sx={{
                height: "20px",
                width: "20px",
                objectFit: "contain",
                flexShrink: 0,
                opacity: 0.25,
              }}
            />
          )}
          {!hit?.icon && <DestinationIcon size={20} sx={{ opacity: 0.25 }} />}
          <Column sx={{ ml: 4 }}>
            <Text
              sx={{
                fontSize: 1,
                fontWeight: !selected && query !== "" ? "bold" : "semi",
                wordBreak: "break-word",
                color: !selected && query !== "" ? "base.4" : "inherit",
                transition: "all 100ms ease-in-out",
                "& strong": {
                  color: "base.7",
                },
                ":hover": {
                  color: selected ? "inherit" : "primary",
                  "& strong": {
                    color: selected ? "inherit" : "primary",
                  },
                },
              }}
            >
              <Highlight attribute="name" highlightedTagName="strong" hit={hit} />
            </Text>
          </Column>
        </Row>

        <Link
          sx={{ justifySelf: "flex-end" }}
          onClick={() => {
            messageIntercom(hit);
          }}
        >
          Request this destination
        </Link>
      </Grid>
    </Selectable>
  );
};
