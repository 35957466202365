import { VFC } from "react";

import { Highlight } from "react-instantsearch-hooks-web";
import { Grid, Image, Text } from "theme-ui";

import { DestinationDefinitionFragment as DestinationDefinition } from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Column, Row } from "src/ui/box";
import { DestinationIcon } from "src/ui/icons";
import { Selectable } from "src/ui/selectable";

import { IntegrationBadge } from "../../integrations/integration-badge";

type DestinationHitProps = {
  category: string;
  definition: DestinationDefinition | null;
  onSelect: (definition: DestinationDefinition | null) => void;
  selected: boolean;
  hit: any;
  query: string;
  sendEvent: (eventType: string, hit: any, eventName?: string) => void;
};

export const DestinationHit: VFC<Readonly<DestinationHitProps>> = ({
  category,
  definition,
  onSelect,
  selected,
  hit,
  query,
  sendEvent,
}) => {
  if (!definition) {
    return null;
  }
  const messageIntercomEarlyAccess = (definition) => {
    if (typeof (window as any)?.Intercom !== "undefined") {
      (window as any)?.Intercom(
        "showNewMessage",
        `Hi, I would like to request early access to the ${definition.name} destination. My use case involves syncing _____ to ${definition.name}. Please contact me when it’s ready.`,
      );
    }
  };

  const selectDefinition = (definition, hit) => {
    sendEvent("click", hit, "Destination clicked");
    analytics.track("Destination clicked", {
      destination_type: definition.name,
      destination_status: definition.status,
      destination_category: category,
    });

    if (definition.status === "alpha") {
      messageIntercomEarlyAccess(definition);
    } else {
      onSelect(definition);
    }
  };

  return (
    <Selectable
      selected={selected}
      sx={{
        position: "relative",
        justifyContent: "start",
        px: 5,
        py: 3,
        bg: selected ? "primaries.0" : "inherit",
        border: "none",
        borderBottom: "small",
        borderRadius: 0,
        overflow: "hidden",
        ":hover": {
          color: selected ? "inherit" : "primary",
        },
      }}
      onSelect={() => selectDefinition(definition, hit)}
    >
      <Grid columns={"repeat(2, 1fr)"} gap={4}>
        <Row sx={{ alignItems: "center" }}>
          {definition?.icon && (
            <Image
              src={`${definition?.icon}?h=40`}
              sx={{
                height: "20px",
                width: "20px",
                objectFit: "contain",
                flexShrink: 0,
              }}
            />
          )}
          {!definition?.icon && <DestinationIcon size={20} />}
          <Column sx={{ ml: 4 }}>
            <Text
              sx={{
                fontSize: 1,
                fontWeight: !selected && query !== "" ? "bold" : "semi",
                wordBreak: "break-word",
                color: !selected && query !== "" ? "base.4" : "inherit",
                transition: "all 100ms ease-in-out",
                "& strong": {
                  color: "base.7",
                },
                ":hover": {
                  color: selected ? "inherit" : "primary",
                  "& strong": {
                    color: selected ? "inherit" : "primary",
                  },
                },
              }}
            >
              <Highlight attribute="name" highlightedTagName="strong" hit={hit} />
            </Text>
          </Column>
        </Row>
        <IntegrationBadge definition={definition} integrationType="destination" isSample={false} />
      </Grid>
    </Selectable>
  );
};
