import { VFC, useState, useCallback, useMemo } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { Image, Text } from "theme-ui";

import { destinationFilterDefinitions, Filters, getHasuaExpFromFilters } from "src/components/filter";
import { CreateViewModal } from "src/components/filter/create-view";
import { Views } from "src/components/filter/views";
import { LabelsCell } from "src/components/labels/labels-cell";
import { Page } from "src/components/layout";
import { BulkDeleteConfirmationModal } from "src/components/modals/bulk-delete-confirmation-modal";
import { Permission } from "src/components/permission";
import { PermissionProvider } from "src/contexts/permission-context";
import { DestinationsBoolExp, DestinationsOrderBy, ResourcePermissionGrant, useDeleteDestinationsMutation } from "src/graphql";
import useQueryState from "src/hooks/use-query-state";
import * as analytics from "src/lib/analytics";
import { Fade } from "src/ui/animations/fade";
import { Row, Column, Box } from "src/ui/box";
import { Button, DropdownButton } from "src/ui/button";
import { Heading } from "src/ui/heading";
import { SearchInput } from "src/ui/input";
import { PageSpinner } from "src/ui/loading";
import { Table, TableColumn, useTableConfig } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { useFiltering } from "src/ui/table/use-filtering";
import { useRowSelect } from "src/ui/table/use-row-select";
import { useDestinations } from "src/utils/destinations";
import { useNavigate } from "src/utils/navigate";
import { openUrl } from "src/utils/urls";

enum SortKeys {
  Name = "name",
  Type = "type",
  UpdatedAt = "updated_at",
}

export const Destinations: VFC = () => {
  const { appEnableResourceTags, appEnablePrimaryResourceFiltering } = useFlags();
  const navigate = useNavigate();
  const [search, setSearch] = useQueryState("search");
  const [confirmingDelete, setConfirmingDelete] = useState<boolean>(false);
  const { selectedRows, onRowSelect } = useRowSelect();
  const [createViewModalOpen, setCreateViewModalOpen] = useState(false);

  const { mutateAsync: bulkDelete, isLoading: loadingBulkDelete } = useDeleteDestinationsMutation();

  const { onSort, orderBy } = useTableConfig<DestinationsOrderBy>({
    defaultSortKey: "updated_at",
    sortOptions: Object.values(SortKeys),
  });

  const {
    state: { creatingView, filters, selectedView, viewNotSaved, views, updatingView },
    actions: { createView, deleteView, resetViewFilters, selectView, updateCurrentView, updateFilters },
  } = useFiltering({ viewKey: "destination" });

  const hasuraFilters: DestinationsBoolExp = useMemo(
    () => getHasuaExpFromFilters(destinationFilterDefinitions, appEnablePrimaryResourceFiltering ? filters : []),
    [appEnablePrimaryResourceFiltering, filters],
  );

  const {
    data: { destinations: allDestinations, definitions: allDefinitions },
  } = useDestinations({ limit: 1000 });

  // used for filtering
  const allDestinationsWithDefinitionData = useMemo(() => {
    return (allDestinations ?? []).map((destination) => {
      const definition = (allDefinitions ?? []).find(({ type }) => type === destination.type);

      return { ...destination, definition };
    });
  }, [allDestinations, allDefinitions]);

  const {
    data: { destinations, definitions },
    loading: destinationsLoading,
    error: destinationsError,
    isRefetching,
  } = useDestinations({
    filters: hasuraFilters,
    destinationsOrderBy: orderBy,
  });

  const filteredDestinations = useMemo(() => {
    return search && destinations && definitions
      ? destinations.filter((destination) => {
          const lowerCaseSearch = search.toLowerCase();
          const definition = definitions.find(({ type }) => type === destination.type);
          return (
            destination.name?.toLowerCase().includes(lowerCaseSearch) ||
            definition?.name.toLowerCase().includes(lowerCaseSearch)
          );
        })
      : destinations;
  }, [search, destinations, definitions]);

  const columns = useMemo(
    (): TableColumn[] => [
      {
        name: "Name",
        sortDirection: orderBy?.name,
        onClick: () => onSort(SortKeys.Name),
        cell: ({ name, tags, type }) => {
          const definition = definitions?.find((definition) => definition.type === type);

          return (
            <Row sx={{ alignItems: "center" }}>
              <Text sx={{ fontWeight: "semi" }}>{name || definition?.name}</Text>
              {appEnableResourceTags && <LabelsCell labels={tags} />}
            </Row>
          );
        },
      },
      {
        name: "Type",
        sortDirection: orderBy?.type,
        onClick: () => onSort(SortKeys.Type),
        cell: ({ type }) => {
          const definition = definitions?.find((definition) => definition.type === type);
          return (
            <Row sx={{ alignItems: "center" }}>
              <Image
                alt={definition?.name}
                src={definition?.icon}
                sx={{ width: "20px", maxHeight: "100%", objectFit: "contain", mr: 2, flexShrink: 0 }}
              />
              <Text sx={{ fontWeight: "semi" }}>{definition?.name}</Text>
            </Row>
          );
        },
      },
      {
        ...LastUpdatedColumn,
        sortDirection: orderBy?.updated_at,
        onClick: () => onSort(SortKeys.UpdatedAt),
      },
    ],
    [definitions, orderBy],
  );

  const placeholder = useMemo(
    () => ({
      title: "No destinations",
      body: search ? "" : "Add a destination to get started.",
      error: "Destinations failed to load, please try again.",
    }),
    [search],
  );

  const onRowClick = useCallback(({ id }, event) => openUrl(`/destinations/${id}`, navigate, event), [navigate]);

  if (destinationsLoading) {
    return <PageSpinner />;
  }

  return (
    <>
      <PermissionProvider permissions={[{ resource: "destination", grants: [ResourcePermissionGrant.Create] }]}>
        <Page crumbs={[{ label: "Destinations" }]} size="full">
          <Column sx={{ mb: 3, width: "100%" }}>
            <Row sx={{ alignItems: "center", justifyContent: "space-between", mb: 8 }}>
              <Row sx={{ alignItems: "center" }}>
                <Heading sx={{ mr: 2 }}>Destinations</Heading>
                {appEnablePrimaryResourceFiltering && (
                  <>
                    <Views value={selectedView} views={views} onChange={selectView} onDelete={deleteView} />
                    {viewNotSaved &&
                      (selectedView === "Default view" ? (
                        <Button
                          sx={{ ml: 2 }}
                          variant="purple"
                          onClick={() => {
                            setCreateViewModalOpen(true);
                          }}
                        >
                          Save as
                        </Button>
                      ) : (
                        <DropdownButton
                          loading={updatingView}
                          options={[
                            {
                              label: "Save as",
                              onClick: () => {
                                setCreateViewModalOpen(true);
                              },
                            },
                            {
                              label: "Reset changes",
                              onClick: () => {
                                resetViewFilters();
                              },
                            },
                          ]}
                          sx={{ ml: 2 }}
                          onClick={updateCurrentView}
                        >
                          Save
                        </DropdownButton>
                      ))}
                  </>
                )}
              </Row>
              <Permission permissions={[{ resource: "destination", grants: [ResourcePermissionGrant.Create] }]}>
                <Button
                  onClick={() => {
                    analytics.track("Add Destination Clicked");
                    navigate("/destinations/new");
                  }}
                >
                  Add destination
                </Button>
              </Permission>
            </Row>
            <Row sx={{ alignItems: "center", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
                <SearchInput placeholder="Search destinations by name..." value={search ?? ""} onChange={setSearch} />

                {appEnablePrimaryResourceFiltering && (
                  <Filters
                    data={allDestinationsWithDefinitionData}
                    filterDefinitions={destinationFilterDefinitions}
                    filters={filters}
                    resourceType="destination"
                    sx={{ ml: 2 }}
                    onChange={updateFilters}
                  />
                )}
              </Box>

              <Permission permissions={[{ resource: "destination", grants: [ResourcePermissionGrant.Delete] }]}>
                <Fade hidden={!selectedRows.length} sx={{ display: "flex", alignItems: "center" }}>
                  <Button sx={{ mr: 4 }} variant="secondary" onClick={() => onRowSelect([])}>
                    Cancel
                  </Button>
                  <Button variant="soft" onClick={() => setConfirmingDelete(true)}>
                    Delete
                  </Button>
                </Fade>
              </Permission>
            </Row>
          </Column>

          <Table
            columns={columns}
            data={filteredDestinations}
            error={Boolean(destinationsError) || Boolean(destinationsError)}
            loading={isRefetching}
            placeholder={placeholder}
            selectedRows={selectedRows}
            onRowClick={onRowClick}
            onSelect={onRowSelect}
          />
        </Page>
      </PermissionProvider>
      <BulkDeleteConfirmationModal
        count={selectedRows.length}
        isOpen={confirmingDelete}
        label="destination"
        loading={loadingBulkDelete}
        onClose={() => setConfirmingDelete(false)}
        onDelete={async () => {
          await bulkDelete({ ids: selectedRows.map(String) });
          onRowSelect([]);
        }}
      />

      <CreateViewModal
        isOpen={createViewModalOpen}
        loading={creatingView}
        onClose={() => setCreateViewModalOpen(false)}
        onSave={createView}
      />
    </>
  );
};
