import { useEffect, useRef, VFC } from "react";

import { SearchBox } from "react-instantsearch-hooks-web";
import { Box } from "theme-ui";

import { SearchIcon } from "src/ui/icons";

type Props = {
  placeholder: string;
};

export const AlgoliaSearchInput: VFC<Props> = (props) => {
  const searchBoxElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (searchBoxElement.current) {
      const searchInput = searchBoxElement.current.querySelector("input") as HTMLElement;
      searchInput.focus();
    }
  }, []);

  return (
    <Box ref={searchBoxElement} sx={{ position: "relative" }}>
      <Box sx={{ position: "absolute", left: 5, top: 5, zIndex: 300 }}>
        <SearchIcon color="base.5" />
      </Box>
      <SearchBox {...props} />
    </Box>
  );
};
