import algoliasearch from "algoliasearch/lite";
import { InstantSearch, Configure } from "react-instantsearch-hooks-web";

import { InsightsMiddleware } from "./search-insights";

const searchClient = algoliasearch(
  import.meta.env.VITE_ALGOLIA_APP_ID as string,
  import.meta.env.VITE_ALGOLIA_SEARCH_API_KEY as string,
);

export const SearchProvider = ({ children }) => {
  return (
    <InstantSearch indexName={`${import.meta.env.VITE_ALGOLIA_INDEX}`} searchClient={searchClient}>
      <Configure distinct exactOnSingleWordQuery="none" filters="type:destination" hitsPerPage={150} page={0} />
      <InsightsMiddleware>{children}</InsightsMiddleware>
    </InstantSearch>
  );
};
