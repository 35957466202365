import { VFC, useCallback } from "react";

import { Flex, Box, ThemeUICSSObject, ThemeUIStyleObject } from "theme-ui";

import { XIcon, SearchIcon } from "src/ui/icons";

import { Input } from "./input";

interface Props {
  sx?: ThemeUICSSObject;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  autoFocus?: boolean;
  variant?: "base" | "large";
}

export const SearchInput: VFC<Props> = ({ variant = "base", ...props }) => {
  const iconSize = variant === "base" ? 16 : 24;
  const xPosition = variant === "base" ? 2 : 5;
  const styles = variant === "base" ? baseStyle : largeStyle;

  const clear = useCallback(() => {
    props.onChange("");
  }, [props.onChange]);

  return (
    <Flex sx={{ alignItems: "center", position: "relative", width: props.sx?.width || "320px" }}>
      <Box sx={{ position: "absolute", left: xPosition }}>
        <SearchIcon color="base.5" size={iconSize} />
      </Box>

      <Box
        sx={{
          position: "absolute",
          right: xPosition,
          cursor: "pointer",
          visibility: props?.value?.length > 0 ? undefined : "hidden",
        }}
        onClick={clear}
      >
        <XIcon color="base.5" size={iconSize} />
      </Box>

      <Input permissioned={false} {...props} sx={{ ...styles, ...(props.sx || {}) }} />
    </Flex>
  );
};

const baseStyle: ThemeUIStyleObject = {
  width: "100%",
  pl: 8,
  pr: 8,
};

const largeStyle: ThemeUIStyleObject = {
  width: "100%",
  px: 14,
  py: 4,
  fontSize: 2,
  "::placeholder": { fontSize: 2 },
};
