import { VFC, useEffect, useRef } from "react";

import { createInfiniteHitsSessionStorageCache } from "instantsearch.js/es/lib/infiniteHitsCache";
import { useHits, useInfiniteHits, UseInfiniteHitsProps } from "react-instantsearch-hooks-web";
import { Text } from "theme-ui";

import { DestinationDefinition } from "src/graphql";
import { Spinner } from "src/ui/loading";

import { DestinationHit } from "./destination-hit";
import { DestinationShadow } from "./destination-shadow";
import { RequestDestination } from "./request-destination";

type Props = {
  category: string;
  destinations: DestinationDefinition[];
  selection: DestinationDefinition | null;
  onSelect: (destination: DestinationDefinition | null) => void;
};

const sessionStorageCache = createInfiniteHitsSessionStorageCache();

const transformItems: UseInfiniteHitsProps["transformItems"] = (items, { results }) => {
  if (results?.query?.length == 0) {
    return items.filter((item) => item.status !== "shadow");
  } else {
    return items;
  }
};

export const DestinationHits: VFC<Readonly<Props>> = ({ category, destinations, selection, onSelect }) => {
  const hitsApi = useInfiniteHits({
    transformItems,
    cache: sessionStorageCache,
  });
  const { sendEvent } = useHits();
  const { hits, results, isLastPage, showMore } = hitsApi;
  const sentinelRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    const pageScrollObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !isLastPage) {
          showMore();
        }
      });
    });

    if (sentinelRef?.current) {
      pageScrollObserver.observe(sentinelRef.current);
    }

    return () => {
      pageScrollObserver.disconnect();
    };
  }, [isLastPage, showMore]);

  if (results?.nbHits === 0) {
    return (
      <>
        <Text sx={{ fontSize: 1, color: "base.4", fontWeight: "bold", py: 3, borderBottom: "small" }}>No results found.</Text>
        <RequestDestination />
      </>
    );
  } else {
    return (
      <>
        <ul aria-label="Available Destinations" style={{ listStyle: "none", padding: 0 }}>
          {Object.values(hits)
            ?.filter((hit) => hit.status !== "shadow")
            ?.map((hit) => (
              <li key={hit.objectID}>
                <DestinationHit
                  category={category}
                  definition={destinations?.find((definition) => definition.name === hit.name) ?? null}
                  hit={hit}
                  query={results?.query ?? ""}
                  selected={hit.objectID === selection?.type}
                  sendEvent={sendEvent}
                  onSelect={onSelect}
                />
              </li>
            ))}
        </ul>
        <ul aria-label="Not yet available Destinations" style={{ listStyle: "none", padding: 0 }}>
          {Object.values(hits)
            ?.filter((hit) => hit.status === "shadow")
            ?.map((hit) => (
              <li key={hit.objectID}>
                <DestinationShadow
                  category={category}
                  hit={hit}
                  query={results?.query ?? ""}
                  selected={hit.objectID === selection?.type}
                  sendEvent={sendEvent}
                />
              </li>
            ))}
          <li
            ref={sentinelRef}
            aria-hidden={isLastPage ? "true" : "false"}
            aria-label="Loading more results"
            style={{ marginTop: 12, marginLeft: 20 }}
          >
            {!isLastPage && <Spinner />}
          </li>
        </ul>
        <RequestDestination />
      </>
    );
  }
};
